<template>
  <section>
    <div class="max-w-6xl pb-24 mx-auto px-4 sm:px-6">
      <div class="">

        <!-- Items -->
          <div class="max-w-sm mx-auto items-start md:max-w-2xl lg:max-w-none">
            <div class="d365-mkt-config" style="display:none" data-website-id="Bbu7cCNcvHIF5IfJYHMiK1ucFk4Tg2JmvvCvPnCgF0c" data-hostname="0d1b45c1155c425da2d99c3bc219b883.svc.dynamics.com"></div>
            <div data-form-block-id="d284d486-04cf-ed11-b597-000d3abca44e"></div>
            <div id="dBbu7cCNcvHIF5IfJYHMiK1ucFk4Tg2JmvvCvPnCgF0c"></div>

            <!-- 1st item -->
          <!-- <a href="javascript:void(Tawk_API.toggle())" data-aos="zoom-y-out">
            <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl transition duration-500 hover:bg-gray-100">
                  <svg class="w-12 h-12 mb-3" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                      <circle class="fill-current text-blue-800" cx="24" cy="24" r="24" />
                      <g stroke-width="2">
                        <path class="stroke-current text-blue-300" d="M19.799 29.601C21.092 31.61 23.826 33 27 33c.59 0 1.163-.051 1.716-.142L33 35v-4.04c1.241-1.057 2-2.44 2-3.96 0-1.552-.792-2.961-2.081-4.027" />
                        <path class="stroke-current text-white" d="M23 14c-5.523 0-10 3.582-10 8 0 1.797.75 3.45 2 4.785V32l4.833-2.416c.996.266 2.059.416 3.167.416 5.523 0 10-3.582 10-8s-4.477-8-10-8z" />
                      </g>
                    </g>
                  </svg>
              <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">Live chat</h4>
              <p class="text-gray-600 text-center">Potřebujete poradit rychle?<br>Využijte náš live chat!</p>
            </div>
          </a> -->

          <!-- 2nd item -->
<!--          <a @click.prevent.stop="emailModalOpen = true" aria-controls="modal_email" data-aos="zoom-y-out" data-aos-delay="150">-->
<!--            <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl transition duration-500 hover:bg-gray-100 cursor-pointer">-->
<!--                  <svg class="w-12 h-12 mb-3" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <g fill="none" fill-rule="evenodd">-->
<!--                      <circle class="fill-current text-blue-800" cx="24" cy="24" r="24" />-->
<!--                      <g stroke-width="2">-->
<!--                        <path class="stroke-current text-blue-300" d="M33 16L22 27" />-->
<!--                        <path class="stroke-current text-white" d="M33 16l-7 19-4-8-8-4z" />-->
<!--                      </g>-->
<!--                    </g>-->
<!--                  </svg>-->
<!--              <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">E-mail</h4>-->
<!--              <p class="text-gray-600 text-center">Írjon nekünk és kérdezzen bátran!</p>-->
<!--            </div>-->
<!--          </a>-->

          <!-- 3rd item -->
          <!-- <a @click.prevent.stop="mobilModalOpen = true" aria-controls="modal_mobil" data-aos="zoom-y-out" data-aos-delay="300">
            <div class="relative flex flex-col items-center p-6 bg-white rounded shadow-xl transition duration-500 hover:bg-gray-100 cursor-pointer">
                  <svg class="w-12 h-12 mb-3" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                      <circle class="fill-current text-blue-800" cx="24" cy="24" r="24" />
                      <g stroke-width="2">
                        <path class="stroke-current text-blue-300" d="M29 22h6v11H13V22h5" />
                        <path class="stroke-current text-white" d="M21 25h-3v-3l9-9 3 3z" />
                      </g>
                    </g>
                  </svg>
              <h4 class="text-xl font-bold leading-snug tracking-tight mb-1">Mobil</h4>
              <p class="text-gray-600 text-center">Chtěli byste raději mluvit<br>s určitou osobou?</p>
            </div>
          </a> -->
        </div>

        <div class="mt-24 max-w-6xl mx-auto px-4 sm:px-6">
          <div>
            <div class="max-w-6xl mx-auto text-left pb-12 md:pb-16">
              <div class="flex items-center pb-4">
                <div class="mr-4">
                  <img class="w-32 sm:w-16" :src="require('@/images/tc/cs-ver.png')" alt="Construsoft" />
                </div>
                <div>
                  <h1 class="h4">Construsoft Kft.</h1>
                  <p class="text-xl text-gray-600">A Trimble termékek hivatalos partnere és viszonteladója</p>
                  <a target="_blank" href="https://construsoft.com/hu/">www.construsoft.hu</a>
                </div>
              </div>
              <!-- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d817.7856654699578!2d19.033261567676973!3d47.52723324757812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741d9540758923f%3A0xcdc881bc28063cab!2sBudapest%2C%20%C3%9Cr%C3%B6mi%20u.%2058%2C%201023%20Hungary!5e0!3m2!1sen!2scz!4v1654156199293!5m2!1sen!2scz" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> -->
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d817.7856654699578!2d19.033261567676973!3d47.52723324757812!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741d9540758923f%3A0xcdc881bc28063cab!2sBudapest%2C%20%C3%9Cr%C3%B6mi%20u.%2058%2C%201023%20Hungary!5e0!3m2!1sen!2scz!4v1654156199293!5m2!1sen!2scz" class="rounded shadow" height="450" width="100%" frameborder="0" style="border:0" allowfullscreen=""></iframe>
            </div>
          </div>
        </div>


        <div>
          <Modal id="modal_mobil" ariaLabel="modal-headline" :show="mobilModalOpen" @handleClose="mobilModalOpen = false">
              <div class="relative text-black">
                <div class="overflow-x-auto p-4 shadow-lg bg-white rounded">
                  <!--modalIndustries(t0, t1, d1, t2, d2, t3, d3, img)-->
                  <p class="h3 mb-2 text-center">Telefonní kontakt</p>

                  <div class="flex flex-wrap lg:flex-no-wrap mb-6">
                      <div class="w-1/3" style="background-size: cover; background-position: center center; border-radius: 5px; box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px; background-image: url(https://www.construsoft.com/sites/default/files/CZE/tc.cz/martin.jpg)" ></div>
                      <div class="w-2/3 pl-6">
                        <h1 class="h4">Martin Maťašovský</h1>
                        <p class="text-lg text-gray-600 mb-2">Máte zájem o Trimble Connect nebo potřebujete více informací? Martin je ten, koho hledáte!</p>
                        <p class="text-m text-gray-400"><b>Tel:</b> <a href="tel:+420777889009">+420 777 889 009</a></p>
                        <p class="text-m text-gray-400"><b>Na telefonu:</b> 07:00 - 17:00</p>
                      </div>

                      <div class="w-1/3 mt-8 md:mt-0" style="background-size: cover; background-position: center center; border-radius: 5px; box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px; background-image: url(https://www.construsoft.com/sites/default/files/CZE/tc.cz/vilem.png)" ></div>
                      <div class="w-2/3 pl-6 mt-8 md:mt-0">
                        <h1 class="h4">Vilém Štibora</h1>
                        <p class="text-lg text-gray-600 mb-6">Vilém řídí náš tým zákaznické podpory Trimble Connect. Společně se svým týmem pro vás najde nejlepší řešení.</p>
                        <p class="text-m text-gray-400"><b>Tel:</b> <a href="tel:+420728488657">+420 728 488 657</a></p>
                        <p class="text-m text-gray-400"><b>Na telefonu:</b> 08:00 - 16:30</p>
                      </div>
                  </div>

                  <div class="text-center mx-auto mb-4">
                    <a @click.prevent.stop="mobilModalOpen = false;" aria-controls="modal_table" class="btn-sm text-white bg-blue-800 hover:bg-blue-700 cursor-pointer">Zavřít</a>
                  </div>
                </div>
              </div>
          </Modal>
        </div>

        <div>
          <Modal id="modal_email" ariaLabel="modal-headline" :show="emailModalOpen" @handleClose="emailModalOpen = false">
            <div class="relative text-black">
                <div class="overflow-x-auto p-4 shadow-lg bg-white rounded">
            <iframe id="contactFormFrame" name="contactFormFrame" style="display:none;"></iframe>
            <form v-if="!feedback" class="mx-auto" id="contactForm" target="contactFormFrame" action="https://marketing.construsoft.com/acton/eform/36418/dfbb6ee7-c9ba-4883-adf2-c8ff8fc430b5/d-ext-0001">
              <div class="flex flex-wrap -mx-3 mb-4">
                <div class="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                  <label class="block text-gray-800 text-sm font-medium mb-1" for="first-name">Név<span class="text-red-600">*</span></label>
                  <input id="firstname" v-model="firstname" name="firstname" type="text" class="form-input w-full text-gray-800" placeholder="Név" required />
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <label class="block text-gray-800 text-sm font-medium mb-1" for="last-name">Vezetéknév <span class="text-red-600">*</span></label>
                  <input id="lastname" v-model="lastname" name="lastname" type="text" class="form-input w-full text-gray-800" placeholder="Vezetéknév" required />
                </div>
              </div>

              <div class="flex flex-wrap -mx-3 mb-4">
                <div class="w-full md:w-1/2 px-3">
                  <label class="block text-gray-800 text-sm font-medium mb-1" for="email">Email <span class="text-red-600">*</span></label>
                  <input id="email" v-model="email" name="email" type="email" class="form-input w-full text-gray-800" placeholder="E-mail" required />
                </div>
                <div class="w-full md:w-1/2 px-3">
                  <label class="block text-gray-800 text-sm font-medium mb-1" for="subject">Cég <span class="text-red-600">*</span></label>
                  <input id="company" v-model="company" name="company" type="text" class="form-input w-full text-gray-800" placeholder="Cég" required />
                </div>
              </div>

              <div class="flex flex-wrap -mx-3 mb-4">
                <div class="w-full px-3">
                  <label class="block text-gray-800 text-sm font-medium mb-1" for="message">Üzenet <span class="text-red-600">*</span></label>
                  <textarea id="message" v-model="message" name="message" rows="4" class="form-textarea w-full text-gray-800" placeholder="Hogyan segíthetünk Önnek?"></textarea>
                </div>
              </div>
              <div class="flex flex-wrap -mx-3 mt-4">
                <div class="w-full px-3">
                  <button @click.prevent="submitContact()" class="btn text-white bg-blue-800 hover:bg-blue-700 w-full">Send</button>
                  <button @click.prevent.stop="emailModalOpen = false;" aria-controls="modal_table" class="btn w-full mt-2 text-white bg-blue-800 hover:bg-blue-700 cursor-pointer">Cancel</button>
                </div>
              </div>
            </form>
            <div v-else class="text-center text-lg">
              {{feedback}}<br>
              <button @click.prevent.stop="emailModalOpen = false;" aria-controls="modal_table" class="btn mt-2 text-white bg-blue-800 hover:bg-blue-700 cursor-pointer">Zavřít</button>
            </div>

                  <div class="text-center mx-auto mb-4">
                  </div>
                </div>
              </div>
          </Modal>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import Modal from './../utils/Modal.vue'

export default {
  name: 'AboutIntro',
  components: {
    Modal,
  },
  data: function () {
    return {
      mobilModalOpen: false,
      emailModalOpen: false,
      feedback: null,
      firstname: null, lastname: null, email: null, company: null, message: null
    }
  },
  mounted() {
    this.$loadScript("https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007")
        .then(() => {
          this.$loadScript("https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007")
              .then(() => {
                console.log("loaded")
              })
              .catch(() => {
                console.error('Cannot load ws-tracking')
              });
        })
        .catch(() => {
          console.error('Cannot load form-loader')
        });
  },
  methods: {
    submitContact(){
      if(this.firstname && this.lastname && this.email && this.company && this.message){
        document.getElementById("contactForm").submit()
        this.feedback = "OK"
      }
    }
  }
}
</script>

<style>
select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%237F7F7F' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: .75rem;
  -webkit-print-color-adjust: unset;
  print-color-adjust: unset
}

[type=checkbox],[type=radio] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #0070f4;
  background-color: #fff;
  border-color: #7f7f7f;
  border-width: 1px;
  --tw-shadow: 0 0 #0000
}

[type=checkbox] {
  border-radius: 0
}

[type=radio] {
  border-radius: 100%
}

[type=checkbox]:focus,[type=radio]:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #0070F4;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow)
}

[type=checkbox]:checked,[type=radio]:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat
}

[type=checkbox]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e")
}

[type=radio]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e")
}

[type=checkbox]:checked:hover,[type=checkbox]:checked:focus,[type=radio]:checked:hover,[type=radio]:checked:focus {
  border-color: transparent;
  background-color: currentColor
}

[type=checkbox]:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat
}

[type=checkbox]:indeterminate:hover,[type=checkbox]:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor
}

form div.marketing-field input[type="checkbox"], form div.marketing-subscription-list input {
  width: 24px!important;
  height: 24px!important;
  margin-right: 8px;
  vertical-align: center;
}
</style>
