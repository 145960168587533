<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 md:pt-40">

        <!-- Section header -->
          <div class="max-w-6xl mx-auto text-center pb-8 md:pb-12">
            <h1 class="h1 mb-4">Kontakt</h1>
            <!-- <p class="text-xl text-gray-600">
            Máte zájem o vyzkoušení Trimble Connect či potřebujete pomoct?
          </p> -->
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutIntro',
}
</script>
